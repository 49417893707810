<script setup>
  import { useWebStore } from '@/store/web.js'
  const store = useWebStore();

</script>
<template>
  <div id="sidemenu">
    <button name="menu" class="sidemenu__btn" v-on:click="store.navOpen=!store.navOpen" v-bind:class="{active:store.navOpen}">
      <span class="top"></span>
      <span class="mid"></span>
      <span class="bottom"></span>
    </button>
    <transition name="translateX">
      <nav v-if="store.navOpen" @click="store.navOpen = false">
          <ul class="sidemenu__list grid gap0">
            <NuxtLink v-for="item in items" v-bind:key="item.text"
              :to="'/' + store.chosenLang + '/' + item.url">
              <li v-if="item.show!==false" class="sidemenu__item align-content-center largeText InterExtraBold capital" >{{item.text}}</li>
            </NuxtLink>
              <li class="sidemenu__item align-content-center largeText InterExtraBold middleMargin w100per sm:hidden" @click.stop>
                <LanguageChooser :mobile="true"/>
              </li>
          </ul>
      </nav>
    </transition>
  </div>
</template>
<script>
import LanguageChooser from "@/components/LanguageChooser.vue";

export default {
  name: 'hamburgerMenu',
  components: {LanguageChooser},
  props:['items'],
  data(){
    return{

    }
  }
}

</script>

<style scoped lang="scss">
#sidemenu {
  margin-left: auto;
  nav {
    width: 80vw;
    // height: calc(100% - #{$headerHeight} - #{$footerHeight});
    height: 80dvh;
    background: white;
    position: fixed;
    top: 20px;
    right: 0;
    z-index: 99;
    transform: translateY(6vh);
  }

  .sidemenu {
    &__btn {
      display: block;
      width: 10vw;
      height: 100%;
      background: transparent;
      border: none;
      position: relative;
      z-index: 100;
      appearance: none;
      cursor: pointer;
      outline: none;

      span {
        display: block;
        width: 6.5vw;
        height: 4px;
        margin: auto;
        background: black;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all .4s ease;

        &.top {
          transform: translateY(-9px);
        }

        &.bottom {
          transform: translateY(9px);
        }
      }
      &.active{
        .top {
          transform: rotate(-45deg);
        }
        .mid{
          transform: translateX(-20px) rotate(360deg);
          opacity: 0;
        }
        .bottom {
          transform: rotate(45deg);
        }
      }

    }
    &__item{
      padding: 10px;
    }


    &__list {
      list-style:none;
      padding: 0;
      margin: 0;
      height: 100%;
    }

    &__item:nth-of-type(2n) {
      background-color: #F5F5F5;
    }
  }
}

.translateX-enter{
  transform:translateX(-200px);
  opacity: 0;
}

.translateX-enter-active,.translateX-leave-active{
  transform-origin: top left 0;
  transition:.2s ease;
}

.translateX-leave-to{
  transform: translateX(-200px);
  opacity: 0;
}

</style>