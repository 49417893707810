<script setup>
import { ref, computed } from 'vue';
import { useWebStore } from '@/store/web.js';

const store = useWebStore();

const selectedLanguage = computed(() => store.chosenLang.toUpperCase());
const selectedFlag = computed(() => `/flags/${store.chosenLang}.webp`);

// This should be a `ref` because it changes state
const showDropdown = ref(false);
const showLanguages = ref(false);

let mobile = ref(store.$state.screenSize === 'mobile')

const languages = {
  hu: '/flags/hu.webp', 
  en: '/flags/en.webp', 
  ro: '/flags/ro.webp',  
  es: '/flags/es.webp'  
};

// Use this function to operate on store without using `this`
function selectLanguage(lang) {
  store.setLangThenNavigate(lang);
  showDropdown.value = false;
  showLanguages.value = false;
}

// Function to toggle language list visibility for mobile
function toggleLanguages() {
  showLanguages.value = !showLanguages.value;
}
</script>

<template>
  <div>
    <div class="language-chooser pos-rel flex justify-center pointer w100per">
      <div
        class="selected-language flex align-items-center p-15 bg-light-grey tr-1s m-left-auto p-rig-30"
        v-if="!mobile"
        @mouseover="showDropdown = true"
        @mouseleave="showDropdown = false"
      >
        <img class="h-35px w-35px" :src="selectedFlag" alt="Selected Language">
        <span class="text m-left-15 fw-700 InterMedium">{{ selectedLanguage }}</span>
        <div
          v-if="showDropdown"
          class="dropdown flex-column pos-abs top-100p bg-white z-100 tr-1s p15 w-max"
          @mouseover="showDropdown = true"
          @mouseleave="showDropdown = false"
        >
          <div
            class="dropdown-item flex align-items-center p-15 pointer"
            v-for="(flag, lang) in languages"
            :key="lang"
            @click="selectLanguage(lang)"
          >
            <img class="h-35px w-35px" :src="flag" :alt="lang">
            <span class="text m-left-15 fw-700 InterMedium">{{ lang.toUpperCase() }}</span>
          </div>
        </div>
      </div>

      <!-- MOBILE -->
      <div
        v-if="mobile && !showLanguages"
        class="language-mobile flex align-items-center p-15 bg-light-grey tr-1s"
        @click="toggleLanguages"
      >
        <img class="h-35px w-35px" :src="selectedFlag" alt="Selected Language">
        <span class="text m-left-15 fw-700">{{ selectedLanguage }}</span>
      </div>

      <div v-if="mobile && showLanguages" class="language-list flex w100per justify-center">
        <div
          class="language-item flex align-items-center p-15 m10 pointer"
          v-for="(flag, lang) in languages"
          :key="lang"
          @click="selectLanguage(lang)"
        >
          <img class="h-35px w-35px" :src="flag" :alt="lang">
          <span class="text m-left-15 fw-700">{{ lang.toUpperCase() }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.language-chooser {
  &.flex {
    display: flex;
  }
}

.selected-language {
  cursor: pointer;
}

.language-mobile {
  cursor: pointer;
}

.dropdown {
  animation: dropdownIn 0.3s ease-out;
}

@keyframes dropdownIn {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.language-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px; /* slight margin for better separation */
}

.language-item {
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: lightgrey;
  transition: background-color 0.3s;
}

.language-item:hover {
  background-color: grey;
}
</style>
